<template>
  <div class="animated fadeIn">
    <b-card>
      <b-tabs content-class="mt-3" justified>
        <b-tab ref="overview-tab" title="Overview" active>
          <form>
            <h5>
              Order details
              <b-spinner v-if="isLoading" small type="grow" class="mb-1" />
            </h5>
            <hr />
            <b-row>
              <b-col lg="3" md="6" sm="6">
                <inline-input
                  :id="controls.order_no.id"
                  :value="id"
                  :label="controls.order_no.label"
                  :readonly="true"
                />
              </b-col>
              <b-col lg="3" md="6" sm="6">
                <inline-date-picker
                  :id="controls.order_date.id"
                  :value-single="data.order_date"
                  :label="controls.order_date.label"
                  :readonly="controls.order_date.readonly"
                  :mode="mode"
                  @changed="updateDateField"
                />
              </b-col>

              <b-col lg="3" md="6" sm="6">
                <inline-select
                  :id="controls.supplier.id"
                  :value="data.supplier"
                  :label="controls.supplier.label"
                  :readonly="controls.supplier.readonly"
                  :options="controls.supplier.options"
                  :allow-empty="true"
                  :multiple="false"
                  :mode="mode"
                  :link-mode="true"
                  @link-clicked="onSupplierLinkClicked"
                  @changed="updateSelect"
                  :required="true"
                />
              </b-col>
              <b-col lg="3" md="6" sm="6">
                <inline-input
                  :id="controls.shipping_cost.id"
                  :value="data.shipping_cost"
                  :label="controls.shipping_cost.label"
                  :readonly="false"
                  :mode="mode"
                  :required="false"
                  @changed="updateField"
                />
              </b-col>
            </b-row>
            <b-row>
              <b-col lg="3" md="6" sm="6">
                <inline-input
                  :id="controls.created.id"
                  :value="data.created"
                  :label="controls.created.label"
                  :readonly="true"
                />
              </b-col>
              <b-col lg="3" md="6" sm="6">
                <inline-input
                  :id="controls.modified.id"
                  :value="data.modified"
                  :label="controls.modified.label"
                  :readonly="true"
                />
              </b-col>

              <b-col lg="3" md="6" sm="6">
                <inline-input
                  :id="controls.closed.id"
                  :value="data.closed"
                  :label="controls.closed.label"
                  :readonly="true"
                />
              </b-col>
            </b-row>
          </form>
          <hr />

          <b-row>
            <b-col>
              <purchases-table
                ref="purchases-table"
                :order-id="this.id"
                :mode="mode"
              ></purchases-table>
            </b-col>
          </b-row>
        </b-tab>

        <b-tab
          :title="controls.tabs.files.title"
          v-if="mode !== $constants.FORM_MODE.CREATE"
        >
          <files-container
            :module-id="$constants.MODULES.InventoryOrders.id"
            :entity-id="id"
            :show-category="true"
            @loaded="onFileContainerLoad"
          />
        </b-tab>

        <b-tab
          :title="controls.tabs.supplier_files.title"
          v-if="mode !== $constants.FORM_MODE.CREATE"
        >
          <files-container
            v-if="data.supplier.id"
            :module-id="$constants.MODULES.Accounts.id"
            :entity-id="data.supplier.id"
            :show-category="true"
            @loaded="onSupplierFileContainerLoad"
          />
        </b-tab>
      </b-tabs>

      <hr />

      <form-submission-actions
        :mode="mode"
        :loading="{ save: saveInProgress, saveAndView: saveAndViewInProgress }"
        :buttons-visibility="{
          previous: $customTable.getPrevKey($route.meta.module, id),
          next: $customTable.getNextKey($route.meta.module, id)
        }"
        @previous-item="
          $router.push({
            name: $route.name,
            params: {
              action: $route.params.action,
              id: $customTable.getPrevKey($route.meta.module, id)
            }
          })
        "
        @next-item="
          $router.push({
            name: $route.name,
            params: {
              action: $route.params.action,
              id: $customTable.getNextKey($route.meta.module, id)
            }
          })
        "
        @save="
          save('tabular').then(response =>
            response ? $router.push({ name: 'Purchase orders' }) : false
          )
        "
        @save-and-view="
          save('view').then(response =>
            response
              ? $router.push({
                  name: 'Order submission',
                  params: { action: 'view', id: response }
                })
              : false
          )
        "
        @edit="
          $router.push({
            name: 'Order submission',
            params: { action: 'edit', id: id }
          })
        "
        @back="$router.push($store.getters['router/previousRoute'])"
      />
    </b-card>

    <b-modal
      ref="supplier-preview-modal"
      centered
      scrollable
      size="lg"
      :title="supplierPreviewModal.title"
    >
      <b-container fluid class="ma-0 pa-0" style="height: 80vh;">
        <AccountSubmissionForm :id="supplierPreviewModal.id" action="preview" />
      </b-container>

      <template #modal-footer="">
        <hr />
        <div class="form-row d-flex  justify-content-end">
          <b-button
            variant="outline-dark"
            class="m-1"
            @click="openSupplierInNewWindow(supplierPreviewModal.id)"
          >
            <font-awesome-icon icon="share" /> Open in new window
          </b-button>
          <b-button
            variant="outline-dark"
            class="m-1"
            @click="closeSupplierPreview()"
          >
            <font-awesome-icon icon="times" /> Close
          </b-button>
        </div>
      </template>
    </b-modal>
  </div>
</template>

<script>
import Vue from "vue";
import moment from "moment";

import FilesContainer from "@/components/FilesContainer";
import CKEditor from "@ckeditor/ckeditor5-vue";
import InlineEditor from "@ckeditor/ckeditor5-build-inline";

Vue.use(CKEditor);

import InlineInput from "@/components/InlineInput";
import InlineSelect from "@/components/InlineSelectMultiple";
import InlineDatePicker from "@/components/InlineDatePicker";

import FormSubmissionActions from "@/components/FormSubmissionActions";

import AccountSubmissionForm from "@/views/RelationshipManagement/AccountSubmissionForm/index.js";

import PurchasesTable from "../PurchasesTable.vue";

import { mapState, mapActions } from "vuex";

export default {
  name: "OrderSubmissionForm",
  props: {
    id: {
      type: [String, Number],
      default: ""
    },
    action: {
      type: String,
      default: ""
    }
  },
  components: {
    InlineInput,
    InlineSelect,
    InlineDatePicker,

    FilesContainer,
    AccountSubmissionForm,
    FormSubmissionActions,
    PurchasesTable
  },
  data: function() {
    return {
      baseApiUrl: "inventory/order",
      isLoading: false,
      saveInProgress: false,
      saveAndViewInProgress: false,
      editor: {
        mode: InlineEditor,
        data: "",
        config: {
          startupFocus: true,
          startupShowBorders: true
          // readOnly: true,
        },
        readonly: false
      },
      supplierPreviewModal: {
        id: "",
        title: ""
      },
      controls: {
        tabs: {
          files: {
            title: "Files"
          },
          supplier_files: {
            title: "Supplier's Files"
          }
        },
        buttons: {
          edit: {
            id: "button:details_edit"
          },
          save: {
            id: "button:details_save"
          }
        },
        order_no: {
          id: "input:order_no",
          label: "Order Number",
          changed: false,
          readonly: true
        },

        order_date: {
          id: "datepicker:order_date",
          label: "Order Date",
          changed: false,
          readonly: false
        },
        supplier: {
          id: "select:supplier",
          label: "Supplier",
          options: [],
          changed: false,
          readonly: false,
          loading: false
        },
        shipping_cost: {
          id: "input:shipping_cost",
          label: "Shipping Cost",
          changed: false,
          readonly: false
        },
        created: {
          id: "input:created",
          label: "Created",
          changed: false,
          readonly: true
        },
        modified: {
          id: "input:modified",
          label: "Modified",
          changed: false,
          readonly: true
        },
        closed: {
          id: "input:closed",
          label: "Closed",
          changed: false,
          readonly: true
        },

        created_by: {
          id: "input:created_by",
          label: "Created By",
          changed: false,
          readonly: true
        },
        modified_by: {
          id: "input:modified_by",
          label: "Modified By",
          changed: false,
          readonly: true
        }
      },
      data: {
        id: "",
        order_date: "",
        supplier: {
          id: "",
          label: ""
        },
        shipping_cost: ""
      },
      selectAll: false,
      selectedRows: [],
      dropdowns: {
        inventoryItem: [],
        deliveryStatus: [],
        amountUnit: [],
        currency: []
      },
      dblclick: undefined,
      serverFilter: undefined
    };
  },
  computed: mapState({
    profile: state => state.profile
  }),
  created() {
    this.initialize();
  },
  mounted() {},
  methods: {
    ...mapActions("profile", ["fetchProfile"]),
    async initialize() {
      if (this.action === "create") {
        this.mode = this.$constants.FORM_MODE.CREATE;
      }
      if (this.action === "edit") {
        this.mode = this.$constants.FORM_MODE.EDIT;
      }
      if (this.action === "view") {
        this.mode = this.$constants.FORM_MODE.VIEW;
      }

      this.data.id = this.id;

      this.loadDictionaries();

      if (this.mode !== this.$constants.FORM_MODE.CREATE && this.id) {
        this.loadData(this.id);
      }

      if (this.mode !== this.$constants.FORM_MODE.CREATE && this.id) {
        this.drawDataTable();
      }

      if (this.mode === this.$constants.FORM_MODE.CREATE) {
        this.data.order_date = moment.utc().format("YYYY-MM-DD");
      }
    },
    drawDataTable() {
      console.log("drawDataTable", this.id);
      /*
      let payload = {
        order_id: this.id
      };
*/
      // this.$refs["purchases-table"].getData(payload);
    },

    onRowSelect(e) {
      this.selectedRows = this.getSelectedRows();
      this.$emit("row-select", e);
    },
    getSelectedRows() {
      let selected = JSON.parse(
        JSON.stringify(this.$refs["dataTable"].selectedRows)
      );

      return selected;
    },
    onInlineDropdownChange(e) {
      let rowIndex = this.dataTable.dataSet.findIndex(
        i => i["ID"] === e.row["ID"]
      );
      let row = this.dataTable.dataSet[rowIndex];

      if (e.column === "Amount Unit") {
        row["amount_unit_id"] = e.value.id;
      }

      if (e.column === "Currency") {
        row["price_unit_id"] = e.value.id;
      }

      if (e.column === "Delivery Status") {
        row["delivery_status_id"] = e.value.id;
      }

      if (e.column === "Item Name") {
        let inventoryItem = this.dropdowns.inventoryItem.find(
          i => i["ID"] === e.value.id
        );

        row["item_id"] = inventoryItem["ID"];
        row["Category"] = inventoryItem["Category"];
        row["Serial Number"] = inventoryItem["Serial Number"];
      }

      Vue.set(this.dataTable.dataSet, rowIndex, row);
    },
    onInlineColumnUpdate(e) {
      let rowIndex = this.dataTable.dataSet.findIndex(i => i["ID"] === e.id);
      let row = this.dataTable.dataSet[rowIndex];

      row[e.name] = e.value;

      if (e.name === "Unit Price" && +row["Total Amount"] > 0) {
        row["Total Price"] = e.value * row["Total Amount"];
      }

      if (e.name === "Total Amount") {
        if (+row["Unit Price"] > 0)
          row["Total Price"] = +row["Unit Price"] * e.value;
      }

      if (e.name === "Total Price" && +row["Total Amount"] > 0) {
        row["Unit Price"] = (e.value / row["Total Amount"]).toFixed(2);
      }

      console.log("row", JSON.stringify(row));

      Vue.set(this.dataTable.dataSet, rowIndex, row);
    },
    onInlineUpdate(e) {
      let row = this.dataTable.dataSet.find(i => i["ID"] === e.id);

      if (e.column === "Amount Unit") {
        row["amount_unit_id"] = e.value.id;
      }

      if (e.column === "Delivery Status") {
        row["delivery_status_id"] = e.value.id;
      }

      if (e.column === "Item Name") {
        row["item_id"] = e.value.id;
      }

      if (!e.value.label) row[e.column] = e.value;
      else row[e.column] = e.value.label;

      if (this.mode === this.$constants.FORM_MODE.VIEW) {
        this.$api
          .put(`inventory/order/item/${row["ID"]}`, row)
          .then(response => {
            this.$form.makeToastInfo(response.message);

            this.drawDataTable();
          })
          .catch(function(error) {
            this.$form.makeToastError(error.message);
            this.drawDataTable();
          });
      }
    },

    onSupplierLinkClicked(e) {
      this.supplierPreviewModal.id = e.id;
      this.supplierPreviewModal.title = `Supplier ${e.label}`;

      this.$refs["supplier-preview-modal"].show();
    },
    openSupplierPreview() {
      this.$refs["supplier-preview-modal"].show();
    },
    closeSupplierPreview: function() {
      this.$refs["supplier-preview-modal"].hide();
    },
    openSupplierInNewWindow(supplier_id) {
      let routeData = this.$router.resolve({
        name: "Account submission",
        params: {
          id: supplier_id,
          action: "view"
        }
      });
      window.open(routeData.href, "_blank");
    },
    onFileContainerLoad(count) {
      if (count > 0) this.controls.tabs.files.title = `Files (${count})`;
    },
    onSupplierFileContainerLoad(count) {
      if (count > 0)
        this.controls.tabs.supplier_files.title = `Supplier's Files (${count})`;
    },

    loadDictionaries() {
      let self = this;

      const suppliers = () => {
        this.$api.get("dictionaries/accounts/suppliers").then(response => {
          self.controls.supplier.options = response.map(u => ({
            id: u.id,
            label: u.name
          }));
        });
      };

      Promise.all([suppliers()]);
    },
    edit() {},

    save(_mode) {
      if (!this.$form.testForm(this.$refs["overview-tab"])) {
        this.$form.makeToastError("Form contains errors");
        return Promise.resolve(false);
      }

      this.saveInProgress = _mode === "tabular";
      this.saveAndViewInProgress = _mode === "view";

      let method = "";

      let url = this.baseApiUrl;

      if (this.mode === this.$constants.FORM_MODE.CREATE) {
        method = "put";
        url = this.baseApiUrl;
      }
      if (this.mode === this.$constants.FORM_MODE.EDIT) {
        method = "put";
        url = `${this.baseApiUrl}/${this.data.id}`;
      }

      this.data.items = this.$refs["purchases-table"].getDataSet();

      this.isLoading = true;

      return this.$api[method](url, this.data)
        .then(response => {
          this.isLoading = false;
          this.saveInProgress = false;
          this.saveAndViewInProgress = false;

          this.$form.makeToastInfo(response.message);

          this.$router.currentRoute.params.id = response.id;

          return response.id;
        })
        .catch(error => {
          this.isLoading = false;
          this.saveInProgress = false;
          this.saveAndViewInProgress = false;

          this.$form.makeToastError(error.message);

          return Promise.resolve(false);
        });
    },
    updateDateField(e) {
      this.updateField(e.id, e.valueSingleAsString, e.mode);
    },
    updateField(field, value, mode) {
      this.$form.updateField(this.baseApiUrl, this, field, value, mode);
    },
    updateSelect(id, value) {
      this.$form.updateField(this.baseApiUrl, this, id, value, this.mode);
    },
    async loadData(id) {
      let self = this;

      this.isLoading = true;

      try {
        let response = await this.$api.get(`${this.baseApiUrl}/${id}`);

        this.isLoading = false;

        if (self.$_.isEmpty(response)) return;

        this.data.id = response["ID"];

        this.data.order_date = response["Order Date"];
        this.data.shipping_cost = response["Shipping Cost"];

        this.data.created = response["Created"];
        this.data.modified = response["Modified"];

        this.data.created_by = response["Created By"];
        this.data.modified_by = response["Modified By"];

        this.data.closed = response["Closed"];

        this.data.supplier = {
          id: response["Account ID"],
          label: response["Account Name"]
        };
      } catch (error) {
        console.log(error);

        this.$form.makeToastError(error);
      } finally {
        this.isLoading = false;
      }
    }
  }
};
</script>

<style scoped>
/*supplier preview modal resize */
.modal-lg {
  max-width: 70vw !important;
  max-height: 70vh !important;
}

table .multiselect {
  width: 300px;
}

table .multiselect__content-wrapper {
  position: relative !important;
}

.ck.ck-editor__editable_inline {
  border: 1px solid #e8e8e8;
  min-height: 150px !important;
}

.ck.ck-editor__editable_inline.ck-focused {
  background-color: #ffffed;
}
</style>

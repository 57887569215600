<template>
  <div class="animated fadeIn">
    <b-row v-if="this.showAddButton">
      <b-col>
        <b-button-group>
          <b-button
            variant="outline-dark"
            size="sm"
            :disabled="dataTable.isInserting"
            @click="addItem()"
            title="Add item"
          >
            <font-awesome-icon icon="plus" /> Add item
          </b-button>
        </b-button-group>
      </b-col>
    </b-row>

    <table-custom
      ref="dataTable"
      name="ct_inventory_order_items"
      :loading="dataTable.isLoading"
      :data="dataTable.dataSet"
      :options="dataTable.options"
      @inline-update="onInlineUpdate"
      @inline-dropdown-change="onInlineDropdownChange"
      @column-update="onInlineColumnUpdate"
    >
      <div slot="Order ID" slot-scope="props">
        <b-link @click="viewItem(props.row)">
          {{ props.row["Order ID"] }}
        </b-link>
      </div>

      <div slot="custom-actions" slot-scope="props">
        <div class="btn-group">
          <button
            v-if="!orderId"
            class="btn btn-primary btn-sm"
            @click="viewItem(props.row)"
            title="View item"
          >
            <font-awesome-icon icon="eye" />
          </button>

          <button
            v-if="
              props.row['ID'] &&
                props.row['Delivery Status'] === 'Not Delivered'
            "
            class="btn btn-primary btn-sm"
            @click="receiveItemModal(props.row)"
            title="Receive item"
          >
            <font-awesome-icon icon="check" />
          </button>

          <button
            class="btn btn-danger btn-sm"
            @click="deleteItem(props.row)"
            title="Delete item"
          >
            <font-awesome-icon icon="trash" />
          </button>
          <button
            :class="[
              props.row['Notes']
                ? 'btn btn-secondary btn-sm'
                : 'btn btn-outline-secondary btn-sm'
            ]"
            @click="toggleDescription(props.row['ID'])"
          >
            <font-awesome-icon icon="file" v-if="!props.row['Notes']" />
            <font-awesome-icon icon="file-alt" v-if="props.row['Notes']" />
          </button>
        </div>
      </div>

      <span slot-scope="props" slot="child_row">
        <ckeditor
          ref="ckeditor"
          @blur="onDescriptionBlur(props.row)"
          :editor="editor.mode"
          v-model="props.row['Notes']"
          :config="editor.config"
        />
      </span>
    </table-custom>

    <receive-item-modal
      ref="receiveItemModal"
      @save="onReceiveItemModalSave"
      @close="onReceiveItemModalClose"
    />
  </div>
</template>

<script>
import Vue from "vue";
import moment from "moment";
import CKEditor from "@ckeditor/ckeditor5-vue";
import InlineEditor from "@ckeditor/ckeditor5-build-inline";

Vue.use(CKEditor);

import TableCustom from "@/components/TableCustom";
import ReceiveItemModal from "./ReceiveItemModal.vue";
import { FORM_MODE } from "@/shared/constants";
import { mapState, mapActions } from "vuex";

export default {
  name: "PurchasesTable",
  props: {
    orderId: {
      type: [String, Number],
      default: ""
    },
    showAddButton: {
      type: Boolean,
      default: true
    },
    mode: {
      type: [Number],
      default: FORM_MODE.VIEW
    }
  },
  components: {
    ReceiveItemModal,

    TableCustom
  },
  data: function() {
    return {
      isLoading: false,

      editor: {
        mode: InlineEditor,
        data: "",
        config: {
          startupFocus: true,
          startupShowBorders: true
          // readOnly: true,
        },
        readonly: false
      },

      selectAll: false,
      selectedRows: [],
      dropdowns: {
        inventoryItem: [],
        deliveryStatus: [],
        amountUnit: [],
        currency: []
      },
      dataTable: {
        view: 1,
        isLoading: false,
        dataSet: [],
        options: {
          uniqueKey: "ID",
          showChildRowToggler: false,
          showEmpty: true,
          filterable: [],
          columns: [
            "ID",
            "Item Name",
            "Category",
            "Serial Number",
            "Currency",
            "Unit Price",
            "Total Amount",
            "Amount Unit",
            "Total Price",
            "Order ID",
            "Order Date",
            "Est Arrival Date",

            "Amount Received",
            "Delivery Status",
            "Actions"
          ],
          editableColumns: [
            "Item Name",
            "Unit Price",
            "Total Price",
            "Currency",
            "Total Amount",
            "Amount Unit",
            "Est Arrival Date",
            "Delivery Status"
          ],
          dropdownColumns: [
            { name: "Item Name", options: [] },
            { name: "Currency", options: [] },
            { name: "Delivery Status", options: [] },
            { name: "Amount Unit", options: [] }
          ],
          perPage: 50,
          slots: ["Order ID"],
          showCustomActions: true,
          //showActions: true,
          showChildRows: true,
          selectableRows: this.selectableRows,
          saveNewRecordCallback: this.saveNewRecordCallback,
          revertNewRecordCallback: this.revertNewRecordCallback
        },
        childRow: {
          showDescription: false
        }
      },

      dblclick: undefined,
      serverFilter: undefined
    };
  },
  computed: mapState({
    profile: state => state.profile
  }),
  created() {
    this.initialize();
  },
  mounted() {},
  methods: {
    ...mapActions("profile", ["fetchProfile"]),
    async initialize() {
      this.loadDictionaries();

      if (this.mode !== this.$constants.FORM_MODE.CREATE && this.orderId) {
        this.getData(this.orderId);
      }
    },

    onRowSelect(e) {
      this.selectedRows = this.getSelectedRows();
      this.$emit("row-select", e);
    },
    getSelectedRows() {
      let selected = JSON.parse(
        JSON.stringify(this.$refs["dataTable"].selectedRows)
      );

      return selected;
    },
    onInlineDropdownChange(e) {
      let rowIndex = this.dataTable.dataSet.findIndex(
        i => i["ID"] === e.row["ID"]
      );
      let row = this.dataTable.dataSet[rowIndex];

      if (e.column === "Amount Unit") {
        row["amount_unit_id"] = e.value.id;
      }

      if (e.column === "Currency") {
        row["price_unit_id"] = e.value.id;
      }

      if (e.column === "Delivery Status") {
        row["delivery_status_id"] = e.value.id;
      }

      if (e.column === "Item Name") {
        let inventoryItem = this.dropdowns.inventoryItem.find(
          i => i["ID"] === e.value.id
        );

        row["item_id"] = inventoryItem["ID"];
        row["Category"] = inventoryItem["Category"];
        row["Serial Number"] = inventoryItem["Serial Number"];
      }

      Vue.set(this.dataTable.dataSet, rowIndex, row);
    },
    onInlineColumnUpdate(e) {
      let rowIndex = this.dataTable.dataSet.findIndex(i => i["ID"] === e.id);
      let row = this.dataTable.dataSet[rowIndex];
      row[e.name] = e.value;

      if (e.name === "Unit Price" && +row["Total Amount"] > 0) {
        row["Total Price"] = e.value * row["Total Amount"];
      }

      if (e.name === "Total Amount") {
        if (+row["Unit Price"] > 0)
          row["Total Price"] = +row["Unit Price"] * e.value;
      }

      if (e.name === "Total Price" && +row["Total Amount"] > 0) {
        row["Unit Price"] = (e.value / row["Total Amount"]).toFixed(2);
      }

      Vue.set(this.dataTable.dataSet, rowIndex, row);
    },
    onInlineUpdate(e) {
      let row = this.dataTable.dataSet.find(i => i["ID"] === e.id);

      if (e.column === "Amount Unit") {
        row["amount_unit_id"] = e.value.id;
      }

      if (e.column === "Delivery Status") {
        row["delivery_status_id"] = e.value.id;
      }

      if (e.column === "Item Name") {
        row["item_id"] = e.value.id;
      }

      if (!e.value.label) row[e.column] = e.value;
      else row[e.column] = e.value.label;

      //if (this.mode === this.$constants.FORM_MODE.VIEW) {
      this.$api
        .put(`inventory/order/item/${row["ID"]}`, row)
        .then(response => {
          this.$form.makeToastInfo(response.message);

          this.getData();
        })
        .catch(function(error) {
          this.$form.makeToastError(error.message);
          this.getData();
        });
      //}
    },
    loadDictionaries() {
      let self = this;

      const items = () => {
        self.$api.post("inventory/items").then(response => {
          self.dropdowns.inventoryItem = response;

          self.dataTable.options.dropdownColumns.find(
            c => c.name === "Item Name"
          ).options = response.map(u => ({
            id: u["ID"],
            label: u["Item Name"]
          }));
        });
      };

      const currencies = () => {
        self.$api.get("dictionaries/inventory/currencies").then(response => {
          self.dropdowns.currency = response;

          self.dataTable.options.dropdownColumns.find(
            c => c.name === "Currency"
          ).options = response.map(u => ({
            id: u["id"],
            label: u["name"]
          }));
        });
      };

      const statuses = () => {
        self.$api
          .get("dictionaries/inventory/delivery-statuses")
          .then(response => {
            self.dropdowns.deliveryStatus = response;

            self.dataTable.options.dropdownColumns.find(
              c => c.name === "Delivery Status"
            ).options = response.map(u => ({
              id: u.id,
              label: u.name
            }));
          });
      };
      const amountUnits = () => {
        self.$api.get("dictionaries/inventory/amount-units").then(response => {
          self.dropdowns.amountUnits = response;

          self.dataTable.options.dropdownColumns.find(
            c => c.name === "Amount Unit"
          ).options = response.map(u => ({
            id: u.id,
            label: u.name
          }));
        });
      };

      Promise.all([items(), statuses(), amountUnits(), currencies()]);
    },

    toggleDescription(id) {
      this.$refs.dataTable.toggleChildRow(id);
    },
    onDescriptionBlur(row) {
      this.onInlineUpdate({
        id: row["ID"],
        column: "Notes",
        value: row["Notes"]
      });
    },
    getDataSet() {
      return this.dataTable.dataSet;
    },
    addItem() {
      let newItem = {
        uid: this.$helpers.uuidv4(),
        ID: this.$constants.CUSTOM_TABLE.NEW_ROW_ID,
        "Item Name": "",
        Currency: "USD",
        price_unit_id: 1,
        "Unit Price": "",
        "Total Price": "",
        "Amount Unit": "",
        "Total Amount": "",
        "Amount Received": "",
        delivery_status_id: 0,
        "Delivery Status": "Not Delivered",
        Notes: "",
        "Est Arrival Date": moment
          .utc()
          .add(10, "days")
          .format("YYYY-MM-DD")
      };

      this.dataTable.dataSet = this.$refs.dataTable.insertNewRow(newItem);

      this.$nextTick(() => {
        this.$refs.dataTable.toggleChildRow(
          this.$constants.CUSTOM_TABLE.NEW_ROW_ID
        );
      });

      // this.dataTable.dataSet.push(newItem);

      this.dataTable.isInserting = true;
    },

    async saveNewRecordCallback(newRecord) {
      let self = this;

      if (!newRecord["Item Name"]) {
        self.$form.makeToastError("Please select an item");
        return;
      }
      if (!newRecord["Total Price"]) {
        self.$form.makeToastError("Please enter total price");
        return;
      }
      if (!newRecord["Total Amount"]) {
        self.$form.makeToastError("Please enter amount");
        return;
      }
      if (!newRecord["Amount Unit"]) {
        self.$form.makeToastError("Please enter amount unit");
        return;
      }

      let data = {
        id: this.$constants.CUSTOM_TABLE.NEW_ROW_ID
      };

      data["order_id"] = this.orderId;
      data["item_id"] = newRecord["item_id"];
      data["Unit Price"] = newRecord["Unit Price"];
      data["Total Price"] = newRecord["Total Price"];
      data["Currency"] = newRecord["Currency"];
      data["price_unit_id"] = newRecord["price_unit_id"];
      data["Total Amount"] = newRecord["Total Amount"];
      data["amount_unit_id"] = newRecord["amount_unit_id"];
      data["Amount Received"] = newRecord["Amount Received"];
      data["delivery_status_id"] = newRecord["delivery_status_id"];
      data["Notes"] = newRecord["Notes"];
      data["Est Arrival Date"] = newRecord["Est Arrival Date"];

      self.dataTable.isInserting = false;
      console.log(
        "self.dataTable.isInserting",
        self.dataTable.isInserting,
        JSON.stringify(data)
      );
      if (this.mode === this.$constants.FORM_MODE.VIEW && this.orderId) {
        this.$api
          .post(`inventory/order/${this.orderId}/item`, data)
          .then(response => {
            self.$form.makeToastInfo(response.message);

            self.getData();
          })
          .catch(function(error) {
            self.$form.makeToastError(error.message);

            self.getData();
          });
      }

      return true;
    },

    async revertNewRecordCallback() {
      this.dataTable.isInserting = false;

      return true;
    },
    viewItem(row) {
      this.$router.push({
        name: "Order submission",
        params: {
          action: "view",
          id: row["Order ID"]
        }
      });
    },

    async deleteItem(row) {
      //let row = this.dataTable.dataSet.find(item => item.ID === row.ID);

      let confirm = await this.$form.showConfirmation(
        `Item #${row["Item Name"]} will be removed. Do you want to proceed?`
      );

      if (!confirm) return;

      let idProp =
        this.mode === this.$constants.FORM_MODE.CREATE ? "uid" : "ID";

      this.dataTable.dataSet = this.dataTable.dataSet.filter(
        i => i[idProp] !== row[idProp]
      );
      let self = this;

      if (this.mode === this.$constants.FORM_MODE.VIEW) {
        self.$api
          .delete(`inventory/order/item/${row.ID}`)
          .then(() => {
            self.$form.makeToastInfo("Item deleted");
          })
          .catch(response => {
            console.log(response);

            self.$form.makeToastError(response.data.message);

            self.getData();
          });
      }
    },
    async getData(payload) {
      this.dataTable.isLoading = true;

      let url = "";

      if (this.orderId) url = `inventory/order/${this.orderId}/items`;
      else url = `inventory/items/purchases`;
      console.log("url", url);
      let response = await this.$api.post(url, payload);

      this.rawData = response;

      this.dataTable.isLoading = false;

      this.dataTable.dataSet = this.rawData;
    },
    receiveItemModal(row) {
      this.$refs.receiveItemModal.show({
        item_id: row["item_id"],
        order_id: row["Order ID"]
      });
    },
    onReceiveItemModalSave(payload) {
      this.$refs.receiveItemModal.hide();
      this.$api
        .post(`inventory/item/${payload.id}/history`, payload)
        .then(response => {
          this.$form.makeToastInfo(response.message);

          this.getData();
        })
        .catch(function(error) {
          this.$form.makeToastError(error.message);
          this.getData();
        });
    },
    onReceiveItemModalClose() {
      this.$refs.receiveItemModal.hide();
    }
  }
};
</script>

<style scoped>
table .multiselect {
  width: 300px;
}

table .multiselect__content-wrapper {
  position: relative !important;
}

.ck.ck-editor__editable_inline {
  border: 1px solid #e8e8e8;
  min-height: 150px !important;
}

.ck.ck-editor__editable_inline.ck-focused {
  background-color: #ffffed;
}
</style>
